<template>
  <custom-dialog :dialog="dialog" width="1600" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.RoleControlPanel')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.basicInfo") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.name')"
                :rules="generalRule"
                v-model="myObj.name"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.hierarchy')"
                :rules="generalRule"
                v-model="myObj.level"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.Function") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="2" md="2">
              <span class="display-1 pl-2">
                {{ $vuetify.lang.t("$vuetify.fenLei") }}
              </span>

              <v-sheet style="border: 1px solid black" class="mt-2">
                <v-treeview
                  :items="funcTypes"
                  :active.sync="active"
                  activatable
                  hoverable
                  class="my-8"
                />
              </v-sheet>
            </v-col>

            <v-col cols="12" lg="10" md="10" class="px-6">
              <v-row>
                <v-col cols="12" class="pt-3 pb-2">
                  <span class="display-1 pl-2">
                    {{ $vuetify.lang.t("$vuetify.FunctionList") }}
                  </span>
                </v-col>

                <v-col cols="12" style="border: 1px solid black; height: 545px">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :label="$vuetify.lang.t('$vuetify.search')"
                        v-model="search"
                        append-icon="mdi-magnify"
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="px-6">
                      <RoleFunctionTable
                        :head="headers"
                        :data.sync="funcData"
                        :search="search"
                        :totalItems="totalItems"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.remarks") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                v-model="myObj.note"
                outlined
                class="mt-4"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.confirm") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import { functionList, functionTypesList, roleInfo } from "@/api/system";

const newSearch = () => {
  return {
    type_code: "",
    skip: 0,
    limit: 1000000,
    key: "",
  };
};

function findSelected(list, id) {
  // let dept = undefined;
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      return list[i];
    }
    // dept = findSelected(list[i].items, id);
    // if (dept != undefined) {
    //   break;
    // }
  }
  return dept;
}

export default {
  components: {
    RoleFunctionTable: () => import("@/components/system/roleFunctionTable"),
  },
  props: {
    dialog: Boolean,
    myObj: Object,
  },
  data() {
    return {
      active: [],
      totalItems: 1,
      searchForm: newSearch(),
      search: "",
      selectFunc: "",
      funcTypes: [],
      funcData: [],
      changeCode: [],
      changeAry: [],
      selectedFunc: [],
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "code",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.FunctionCode"),
          value: "type_code",
          width: "140",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.toView"),
          value: "list",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Add"),
          value: "add",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Delete"),
          value: "del",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.modify"),
          value: "modify",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Colum"),
          value: "row_choice",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Roll"),
          value: "column_choice",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate() == true) {
        this.$emit("handleData", this.myObj, this.funcData);
        this.funcData = [];
        this.selectedFunc = [];
      } else {
        console.log("false");
      }
    },
    cancelForm() {
      this.resetValidation();
      this.funcData = [];
      this.selectedFunc = [];
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getFuncTypes() {
      functionTypesList().then((response) => {
        if (response.meta === 0) {
          this.funcTypes = [{ name: "全部", code: "" }, ...response.items];
        }
      });
    },
    getTableData() {
      functionList(this.searchForm).then((response) => {
        if (response.meta === 0) {
          if (response.items != null) {
            this.totalItems = response.total;
            this.funcData = [...response.items];
          } else {
            this.funcData = [];
            this.totalItems = 1;
          }
          this.setTableData();
          this.getRoleEditInfo();
        }
      });
    },
    setTableData() {
      if (this.funcData) {
        this.funcData.forEach((item, index) => {
          item.disable = true;
          item.list_show = item.list;
          item.add_show = item.add;
          item.del_show = item.del;
          item.modify_show = item.modify;
          item.row_show = item.row;
          item.column_show = item.column;
          item.list = false;
          item.add = false;
          item.del = false;
          item.modify = false;
          item.row = false;
          item.column = false;
          this.$set(item, "row_choice", "");
          this.$set(item, "column_choice", []);
          item.rowList = [];
          item.columnList = [];
          item.row_fields.forEach((subItem) => {
            // var json = {
            //   value: subItem,
            //   label: subItem
            // }
            // this.funcData[index].rowList.push(json);
            this.funcData[index].rowList.push(subItem);
          });
          item.column_fields.forEach((subItem) => {
            // var json = {
            //   value: subItem,
            //   label: subItem
            // }
            // this.funcData[index].columnList.push(json);
            this.funcData[index].columnList.push(subItem);
          });
        });
      }
      this.funcData.forEach((obj) => {
        this.setChange(obj);
      });
    },
    setChange(data) {
      if (
        data.list ||
        data.add ||
        data.del ||
        data.modify ||
        data.row ||
        data.column ||
        data.row_choice ||
        data.column_choice
      ) {
        if (this.changeCode.indexOf(data.name) == -1) {
          this.changeAry.push(data);
          this.changeCode.push(data.name);
        }
      }
    },
    getRoleEditInfo() {
      if (this.myObj.id == "") {
        return;
      }
      roleInfo(this.myObj.id).then((res) => {
        if (res.meta === 0) {
          this.myObj.id = res.item.id;
          this.myObj.name = res.item.name;
          this.myObj.level = res.item.level;
          this.myObj.note = res.item.note;
          for (var i in res.item.functions) {
            this.funcData.forEach((obj) => {
              if (obj.code === i) {
                obj.list = res.item.functions[i].list;
                obj.add = res.item.functions[i].add;
                obj.del = res.item.functions[i].del;
                obj.modify = res.item.functions[i].modify;
                obj.row = res.item.functions[i].row;
                obj.column = res.item.functions[i].column;
                obj.row_choice = res.item.functions[i].row_fields;
                obj.column_choice = res.item.functions[i].column_fields;
                this.setChange(obj);
              }
            });
          }

          // this.changeAry.forEach(row => {
          //   this.funcData.forEach(item => {
          //     if (item.id === row.id) {
          //       item.disable = row.disable
          //       item.list = row.list
          //       item.add = row.add
          //       item.del = row.del
          //       item.modify = row.modify
          //       item.row_choice = row.row_choice
          //       item.column_choice = row.column_choice
          //     }
          //   })
          // })

          // this.dynamicTags = res.item.column_fields
          // this.optName = this.$t('tools.confirmChange')
          // this.myObj.type_code = res.item.type_code
        }
      });
    },
    valuation() {
      this.changeAry.forEach((item) => {
        this.funcData.forEach((row) => {
          if (item.id === row.id) {
            item.disable = row.disable;
            item.list = row.list;
            item.add = row.add;
            item.del = row.del;
            item.modify = row.modify;
            item.row_choice = row.row_choice;
            item.column_choice = row.column_choice;
          }
        });
      });
    },
  },
  watch: {
    active() {
      if (this.active.length > 0) {
        const temp = findSelected(this.funcTypes, this.active[0]);
        this.searchForm.type_code = temp.code;
        this.valuation();
        this.getTableData();
      }
    },
    dialog() {
      this.getFuncTypes();
      this.getTableData();
    },
  },
  mounted() {
    // this.getFuncTypes();
    //this.getTableData();
  },
};
</script>
